import { Service } from '@quno/patient-journey/src/constants/usercentrics';
import { useContext, useEffect, useState } from 'react';
import { AppInitContext } from '@quno/patient-journey/src/context/AppInitContext';
import { CookieContext } from '@quno/patient-journey/src/context/CookieContext';
import { isServiceAllowed } from './CookieNotification';
import type { BrandFragment } from '@quno/patient-journey/src/contentful/generated/graphql';

type Props = {
  brand: BrandFragment | null;
};

const GoogleTagManager = ({ brand }: Props): JSX.Element | null => {
  const { hasAppInit } = useContext(AppInitContext);
  const { cookies } = useContext(CookieContext);
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    if (hasAppInit) {
      if (!brand || !brand?.gtmId) {
        return;
      }

      const script = document.createElement('script');

      if (brand?.usercentricsSettingsId) {
        script.type = 'text/plain';
        script.dataset.usercentrics = Service.GOOGLE_TAG_MANAGER;
      }

      script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${brand?.gtmId}');

      gtag("consent", "default", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 2000 // milliseconds to wait for update
      });

      // Enable ads data redaction by default [optional]
      gtag("set", "ads_data_redaction", true);

      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${brand?.gtmId}');
      `;

      document.body.appendChild(script);

      return () => {
        if (script && script.parentNode?.nodeName === 'BODY') {
          document.body.removeChild(script);
        }
      };
    }
  }, [hasAppInit]);

  useEffect(() => {
    const serviceAllowed = isServiceAllowed(
      cookies,
      Service.GOOGLE_TAG_MANAGER,
    );

    setAllowed(serviceAllowed.serviceStatus);
  }, [cookies]);

  if (!allowed) {
    return <></>;
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${brand?.gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

export default GoogleTagManager;
