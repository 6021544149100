export default {
  app: {
    domains: {
      production: 'quno-next.now.sh',
      staging: 'qunomedical.vercel.app',
      local: 'localhost:3030',
      base: {
        production: 'qunomedical.com',
        local: 'localhost',
      },
      proxy: {
        production: 'www.qunomedical.com',
        staging: 'www-staging.qunomedical.com',
      },
    },
  },
  retry: {
    factor: 1,
    retries: 20,
    minTimeout: 3000,
  },
  locales: [
    {
      main: 'en',
      contentful: 'en-US',
      fullName: 'locales.en',
    },
    {
      main: 'de',
      contentful: 'de-DE',
      fullName: 'locales.de',
    },
  ],
  defaultLocale: 'en',
  currencies: {
    EUR: { name: 'EUR', symbol: '€' },
    GBP: { name: 'GBP', symbol: '£' },
    USD: { name: 'USD', symbol: '$' },
  },
  defaults: {
    imageFallbackSize: 500,
    secondsForPageRevalidation: 600,
    serviceFee: 250,
    qdLoadingScreenTimeSeconds: 5,
    bookingApi: 'https://booking.api.qunomedical.com',
    qunoApi: 'https://api.qunomedical.com',
    facebookTrackingId: '1707862066157334',
    googleAnalytics: 'UA-64043738-4',
    googleVerification: 'UF6qe2rlnzPVWD9aExgWqsS_8sYlA6r_Tsu2OGnrEYE',
    country: 'GB',
    currency: 'EUR',
    maxQunoscorePoints: 35,
    newPostsCount: 3,
    postsPerPage: 6,
    tagsCount: 15,
    treatmentsCardSlider: {
      threshold: 2,
      responsive: {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        desktop: {
          breakpoint: { max: 2999, min: 1024 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: { max: 1023, min: 768 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1,
          partialVisibilityGutter: 60,
        },
      },
    },
    treatmentsCardItemCount: 4,
    // eslint-disable-next-line security/detect-unsafe-regex
    emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w)*\.([a-z]{2,})+$/i,
    email: 'contact@qunomedical.com',
    whatsApp: '447723488550',
    messengerUrl: 'https://m.me/qunomedical',
    algolia: {
      hitsPerPage: 9,
      paginationPadding: 1,
      refinementListLimit: 25,
    },
    zapierCatchUrl: 'https://hooks.zapier.com/hooks/catch',
    beforeAndAfterPhotosPreload: 10,
    beforeAndAfterPhotosGridContainerLimit: 500,
  },
  pages: {
    contentTypes: [
      'generalPage',
      'specialityPage',
      'doctorProfile',
      'clinicPage',
      'authorList',
      'clinic',
      'splitTest',
    ],
    generalPage: {
      header: 'bg-secondary',
    },
    treatmentPage: {
      header: 'bg-white',
    },
    doctorProfile: {
      header: 'bg-white',
    },
    blogPage: {
      en: 'blog',
      de: 'blog',
    },
  },
  subpages: {
    contentTypes: ['treatmentPage', 'author'],
    parentTypes: {
      author: 'authorList',
    },
  },
  level_3: {
    contentTypes: ['locationPage'],
  },
  level_4: {
    contentTypes: ['locationPage'],
  },
  dynamicLengthPages: {
    contentTypes: ['landingPage', 'referFriendRewardLandingPage'],
  },
  cache: {
    root: '.cache',
    tailwind: 'tailwind.json',
  },
  countries: {
    GB: {
      phone: '+44 1466 455033',
      currency: 'GBP',
      alternate: 'UK',
    },
    DE: {
      phone: '+49 30 200042054',
      currency: 'EUR',
      whatsApp: '4915735988550',
    },
  },
};
